import { IconType, ArgsProps } from 'antd/es/notification'
import { Middleware } from 'redux'

import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons'

import { displayTime, timestampToMoment } from '@anews/utils'
import { CalendarEvent } from '@anews/types'

import i18n from '../../i18n'

import { labelReminderMinutes } from '../../utils/calendar-utils'
import { notifyOnce } from '../../utils/notification-utils'

import {
  NotificationActionType as ActionType,
  NotificationAction,
} from '../actions/notification-actions'
import { RootState } from '../reducers'

const UNAUTHORIZED = 401
const CONFLICT = 409
const PRECONDITION_FAILED = 412

function innerResponse(error: any) {
  return (
    (error && error.response && error.response.data) || (error && error.response) || { status: -1 }
  )
}

// Obtem notificação para eventos de calendario
function getCalendarEventNotification(event: CalendarEvent, category: IconType) {
  const { id, reminder, name, startDate, calendar } = event

  const hourMinute = `${displayTime(timestampToMoment(startDate))}`
  const reminderLabel = labelReminderMinutes(reminder)
  return {
    key: `Event_${id}`,
    category,
    message: i18n.t('calendar:event', {
      reminder: reminderLabel,
      name,
      calendarName: calendar?.name || '',
      time: hourMinute,
    }),
    icon: faCalendarAlt,
    manualDismiss: true,
  }
}

function buildNotification(
  action: NotificationAction,
  category: IconType,
  placement: ArgsProps['placement'],
) {
  // /////// Notificações de eventos do calendario
  if (action.type === ActionType.EVENT) {
    return getCalendarEventNotification(action.payload.event, category)
  }
  // /////////////////

  const { type, payload } = action
  const { manualDismiss } = payload

  let msg = payload.message || i18n.t(category as any)
  let desc = payload.description

  // Se não for erro exibe uma mensagem simples
  if (type !== ActionType.ERROR) {
    return { category, message: msg, description: desc, manualDismiss, placement }
  }

  const key = `open${Date.now()}` // key para close
  const resp = innerResponse(payload.error)

  /*
   * Gera uma string para a opção de copiar a mensagem de erro para o clipboard.
   *
   * Um stringify apenas no "payload.error" não deu certo. Ele as vezes não gera
   * a string para todo o conteúdo do objeto. Por faz para payload.error e para o
   * retorno de innerResponse() de forma separada.
   *
   * payload.error costuma ser o erro do frontend, e innerResponse() costuma
   * retornar o erro do backend. Achei interessante copiar os dois.
   */
  const details =
    resp === payload.error
      ? JSON.stringify(resp)
      : JSON.stringify({ error: payload.error, innerResponse: resp })

  if (resp.status === UNAUTHORIZED) {
    msg = i18n.t('error:sessionExpired')
    desc = i18n.t('error:sessionExpiredDetails')
  }
  if (resp.status === CONFLICT) {
    msg = i18n.t('error:conflict')
    desc = resp.message
  }
  if (resp.status === PRECONDITION_FAILED) {
    msg = i18n.t('error:preconditionFailed')
    desc = resp.message
  }

  return {
    key,
    category,
    details,
    placement,
    message: msg,
    description: desc,
    manualDismiss,
    duration: 10, // erros tem duração maior
  }
}

// https://www.brunonardini.com.br/desenv-front-end/notificacoes-toast-react-e-redux

const notificationMiddleware: Middleware = ({ getState }) => next => action => {
  let placement: ArgsProps['placement']

  try {
    // Tenta ver se o plugin MOS está aberto. Se estiver, exibe a notificação no canto esquerdo
    const state = getState() as RootState
    if (state.mosplugin.open) {
      placement = 'topLeft'
    }
  } catch (e) {
    // Não faz nada
    console.warn('Failed to get MOS plugin state')
  }

  try {
    switch (action.type) {
      case ActionType.SUCCESS:
        notifyOnce(buildNotification(action, 'success', placement))
        break
      case ActionType.INFORMATION:
        notifyOnce(buildNotification(action, 'info', placement))
        break
      case ActionType.EVENT:
        notifyOnce(buildNotification(action, 'info', placement))
        break
      case ActionType.WARNING:
        notifyOnce(buildNotification(action, 'warning', placement))
        break
      case ActionType.ERROR:
        notifyOnce(buildNotification(action, 'error', placement))
        break
      default:
        break
    }
  } catch (err) {
    console.error('[notification-middleware]', err)
  }
  return next(action)
}

export default notificationMiddleware
